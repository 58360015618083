import {inject} from '@angular/core';

import {ConfigService} from '../../../../api/services/config-service';
import {of, zip} from 'rxjs';
import {UserService} from '../../../../api/services/users.service';
import {catchError, map, tap} from 'rxjs/operators';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot,
                                           state: RouterStateSnapshot) => {
    const userService = inject(UserService);
    return zip(inject(ConfigService).refreshConfig(), userService.init()).pipe(
        // If no user exists, we can't continue
        map(([, user]) => !!user?._id),
        // In case of an error, always return false
        catchError(() => of(false)),
        // Finally, if canActivate is false we return false and redirect using the user service, this should be redone
        // When we get the chance we should clean up the userService
        tap((canActivate) => canActivate || userService.logoutAndRedirectToLogin(false, state.url))
    );
}
